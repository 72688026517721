<template>
  <div class="material-warehouse-library">
    <!-- 只有选择三图的时候 才展示三图选择区域 -->
    <div class="three_pic" v-if="pictureSizeMode === '3'">
      <div class="three_pic_item_wrap">
        <div
          @click="handlePicItemClick(index)"
          class="three_pic_item"
          v-for="(item, index) in new Array(120)"
          :key="index"
          :class="activeIndex === index ? 'activeItem' : ''"
        >
          <img
            :src="groupList[index].src"
            alt=""
            v-if="groupList[index] && groupList[index].src"
          />

          <span v-else>+</span>

          <div
            v-if="groupList[index] && groupList[index].src"
            class="lajitong"
            @click.stop
          >
            <i class="iconfont icon-lajitong" @click="deletePic(index)"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="right_wrap">
      <div class="upload-files">
        <span>素材库</span>
        <div>
          <el-button
            v-if="mediaType === 'image' && pictureSizeMode !== '3'"
            type="warning"
            style="padding: 9px 10px !important"
            @click="uploadFiles('image')"
            >上传图片素材</el-button
          >
        </div>
      </div>
      <div
        class="detail border-radius-6 box-shadow-light-grey height-calc-type1"
        style="background: #fff"
      >
        <div class="nav-filter">
          <div class="filter-list">
            <div class="advantage-filter">
              <span>高级筛选</span>
              <div class="flex-row-start-start-wrap" style="flex-grow: 1">
                <el-input
                  v-model="params.title"
                  placeholder="素材标题/文案"
                  clearable
                  class="margin-right-ten"
                  style="width: 300px"
                >
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="searchData"
                  ></el-button>
                </el-input>
                <!-- 只针对视频素材 -->
                <el-select
                  v-if="params && params.materialType === 1"
                  v-model="params.duration"
                  placeholder="时长"
                  class="margin-right-ten"
                  style="width: 180px"
                  clearable
                  filterable
                  @change="changeDuration"
                >
                  <el-option
                    v-for="item in durationOptions"
                    :key="JSON.stringify(item.value)"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <el-date-picker
                  class="margin-right-ten"
                  v-model="params.createStartTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="上传开始"
                  @change="handleTimeChange('start')"
                ></el-date-picker>
                <el-date-picker
                  class="margin-right-ten"
                  v-model="params.createEndTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="上传结束"
                  @change="handleTimeChange('end')"
                ></el-date-picker>
                <el-select
                  v-model="params.adminUserId"
                  class="margin-right-ten"
                  placeholder="发布人"
                  style="width: 180px"
                  clearable
                  filterable
                  @change="searchData"
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <!-- <span
                v-if="getChooseTags().length > 0"
                style="
                  flex-grow: 1;
                  padding-right: 0;
                  text-align: right;
                  color: #ccc;
                  font-weight: bold;
                  font-size: 12px;
                  margin-top: 10px;
                "
              >
                <el-button
                  type="warning"
                  class="button-small"
                  size="small"
                  @click="delFilterTagAll"
                  >清除所有</el-button
                >
                所选{{ isTagGroup ? "项" : "" }}：
                <span v-for="(item, index) in getChooseTags()" :key="index">
                  <span style="color: #00bf8a">{{ item.name }}</span>
                  <i
                    class="el-icon-error"
                    style="
                      font-size: 14px;
                      color: #fd5d5a;
                      cursor: pointer;
                      padding-left: 5px;
                    "
                    @click="delFilterTag(index,item)"
                  ></i>
                </span>
              </span>-->
              </div>
            </div>
            <div class="tags-filter" v-loading="loading">
              <div class="title">标签筛选</div>
              <div
                class="tag-list"
                @mouseleave.stop="handleContainerMouseLeave"
              >
                <ul class="underline-navbar">
                  <li
                    v-for="(item, index) in tagsList"
                    :key="index"
                    @mouseenter="handleMouseEnter($event, index, item)"
                    @click.stop="
                      ($event) => {
                        handleTagGroupClick(item, $event, index)
                      }
                    "
                  >
                    <span>{{ item.tagGroupName }}</span>
                    <div
                      :ref="`navItemList`"
                      class="subtag-list"
                      @mouseleave.stop="handleMouseLeave($event, index)"
                    >
                      <c-input
                        class="search-input"
                        :ref="`cInputList`"
                        v-model="search"
                        placeholder="请输入标签名称!"
                        style="width: 200px; margin-bottom: 10px"
                      ></c-input>
                      <ul class="subtag-ul">
                        <template
                          v-if="
                            Array.isArray(item.tagItems) &&
                            item.tagItems.length > 0
                          "
                        >
                          <li
                            v-for="(tag, index2) in getTargetList(item)"
                            :data-index="index"
                            :key="index2"
                            @click.stop="
                              ($event) => {
                                handleTagClick(tag, item, $event)
                              }
                            "
                          >
                            {{ tag.name }}
                          </li>
                        </template>
                        <template v-else>
                          <custom-icon tipsText="暂无标签项"></custom-icon>
                        </template>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
              <!-- <el-button
              v-if="tagsList.length > 0"
              type="text"
              @click="checkMore"
              >{{ showMoreTag ? "收起" : "查看更多" }}</el-button
            >-->
              <div
                v-if="tagsList.length === 0"
                style="margin-top: 5px; color: #ccc"
              >
                暂无标签组
              </div>
            </div>
            <div
              v-if="getChooseTags().length > 0"
              class="flex-row-start-start-wrap choose-tags"
            >
              <el-button
                type="warning"
                class="button-small"
                size="small"
                @click="delFilterTagAll"
                >清除所有</el-button
              >
              <div>
                所选{{ isTagGroup ? '项' : '' }}：
                <span
                  v-for="(item, index) in getChooseTags()"
                  style="margin-right: 10px"
                  :key="index"
                >
                  <span style="color: #00bf8a">{{ item.name }}</span>
                  <i
                    class="el-icon-error"
                    style="
                      font-size: 14px;
                      color: #fd5d5a;
                      cursor: pointer;
                      padding-left: 2px;
                    "
                    @click="delFilterTag(index, item)"
                  ></i>
                </span>
              </div>
            </div>
            <div
              class="hover-part"
              ref="hoverPart"
              @mouseleave="handleMouseLeave"
            ></div>
          </div>
        </div>
        <div class="main-container">
          <div class="nav-type">
            <span
              v-if="mediaType === 'video' || !mediaType"
              style="margin-right: 10px"
              @click="
                ($event) => {
                  changeType(1)
                }
              "
              :class="{ 'active-text': params.materialType === 1 }"
            >
              视频素材
              <span class="active-line" v-if="params.materialType === 1"></span>
            </span>
            <span
              v-if="mediaType === 'image' || !mediaType"
              @click="
                ($event) => {
                  changeType(2)
                }
              "
              :class="{ 'active-text': [2, 3].includes(params.materialType) }"
            >
              图片素材
              <span
                class="active-line"
                v-if="[2, 3].includes(params.materialType)"
              ></span>
            </span>
            <span>{{
              `${
                isValidArray(selection)
                  ? `（已勾选${selection.length}个素材）`
                  : ''
              }`
            }}</span>
            <div class="tips">组图最多只显示6张</div>
          </div>
          <div class="library-container">
            <div class="library-header">
              <div>共{{ total }}条数据</div>
            </div>
            <div class="library-container-list" v-infinite-scroll="getList">
              <library-item
                v-for="(item, index) in dataList"
                :key="index"
                :info="item"
                :selection="selection"
                :editable="false"
                @handleItemClick="handleItemClick(item, index)"
                @editItem="editItem"
                @delLibrary="delLibrary"
                @addClick="addClick"
              ></library-item>
            </div>
          </div>
          <slot name="footer"></slot>
        </div>
      </div>
    </div>

    <add-picture
      :canUploadBatchPic="false"
      :show="showAddPicDialog"
      @close="showAddPicDialog = false"
      @freshData="freshData"
      @transformData="transformData"
    ></add-picture>
    <!-- <el-backtop target="#mainCont" :visibility-height="500"></el-backtop> -->
  </div>
</template>

<script>
import {
  getMaterialWareList,
  getMaterialDetail,
  getTagGroupList,
  delMaterialWare,
  getTagItemList,
} from '@/api/material'
import { mapGetters } from 'vuex'
import { isValidArray } from '@/assets/js/utils'
import LibraryItem from '@/views/material-warehouse/library/library-item.vue'
import AddPicture from '@/views/material-warehouse/library/add-picture.vue'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    maxNum: {
      type: [Number, String],
      default: 30,
    },
    mediaType: {
      type: String,
      default: 'image',
    },
    //图片上传模式  1：上传单张的小图  2：上传单张的大图 3：上传三图
    pictureSizeMode: {
      type: String,
      default: '1',
    },
    groupListP: {
      type: Object,
      default: {},
    },
    onlySingle: {
      type: Boolean,
      default: false,
    },
    // showPic:{
    //   type:Boolean,
    //   default:true
    // },
    // showVideo:{
    //   type:Boolean,
    //   default:false
    // }
  },
  data() {
    return {
      page: 1,
      total: 0,
      tagPageSize: 1, // 针对标签组设置的分页值
      params: {
        materialType: 2,
      },
      selection: [],
      firstInitData: true,
      pageSize: 24,
      resetTagFlag: false,
      curInfo: {},
      canShowMoreTag: false,
      canPushTagList: false,
      startTime: null,
      endTime: null,
      isClickTagGroup: false, // 是否通过点击标签组来进行筛选
      puterList: [],
      groupTagIds: [], //  标签组ids
      tagItemIds: [], // 标签项ids
      loading: false,
      showMoreTag: false,
      durationOptions: [
        {
          label: '10秒以内',
          value: 1,
        },
        {
          label: '10秒 ~ 30秒',
          value: 2,
        },
        {
          label: '30秒 ~ 1分钟',
          value: 3,
        },
        {
          label: '1分钟 ~ 2分钟',
          value: 4,
        },
        {
          label: '2分钟以上',
          value: 5,
        },
      ],
      isShowPreview: false,
      showAddDialog: false,
      showAddPicDialog: false,
      editCurInfoWithVideo: null,
      editCurInfoWithPic: null,
      tagsList: [],
      dataList: [],
      tagName: [],
      debounceFlag: false, // 防止滚动到底部是，重复请求数据
      isTagGroup: false, // 是否点击了标签组进行筛选
      options: [
        {
          id: 1,
          name: '按对应广告数降序',
        },
        {
          id: 2,
          name: '按投放天数降序',
        },
        {
          id: 3,
          name: '按投放时间降序',
        },
        {
          id: 4,
          name: '按广告数升序',
        },
        {
          id: 5,
          name: '按投放天数升序',
        },
        {
          id: 6,
          name: '按投放时间升序',
        },
      ],
      isClickTagSingle: false, // 是否点击了标签项进行筛选
      canContionalLoading: true, // 当滚动到底部是，是否可以加载数据
      showAddTagDialog: false,
      targetGroupIds: [],
      targetTagIds: [],
      searchAddTagName: '',
      tagsListWithAdd: [],
      copyTagsListWithAdd: [], // 创建一个tagsListWithAdd的副本
      tagSelection: [],
      videoSelection: [],
      buttonLoading: false,
      imageList: [],
      search: '',
      booksNameIds: [], // 书籍名称标签
      putChannelIds: [], // 投放渠道标签
      imageTypeIds: [], // 图片类型标签
      videoTypeIds: [], // 视频类型标签
      booksTypeIds: [], // 书籍类型标签
      isValidArray,
      info: [],
      curTagInfo: {},
      copyTagsList: [], // !
      mappingIds: {
        2: {
          en: 'booksTypeIds',
          cn: '书籍类型',
        },
        3: {
          en: 'videoTypeIds',
          cn: '视频类型',
        },
        4: {
          en: 'imageTypeIds',
          cn: '图片类型',
        },
        5: {
          en: 'putChannelIds',
          cn: '投放渠道',
        },
        6: {
          en: 'booksNameIds',
          cn: '书籍名称',
        },
      },
      activeIndex: 0,
      groupList: {},
    }
  },
  mounted() {
    this.initData()
  },
  computed: {
    ...mapGetters(['userList']),
  },
  watch: {
    searchAddTagName: {
      handler(newV) {
        if (newV) {
          this.tagsListWithAdd = JSON.parse(
            JSON.stringify(this.copyTagsListWithAdd)
          ).filter((item) => item.name.includes(newV))
        } else {
          this.tagsListWithAdd = JSON.parse(
            JSON.stringify(this.copyTagsListWithAdd)
          )
        }
      },
    },
    show: {
      handler(newV) {
        if (newV) {
          this.initData()
        } else {
          this.resetData()
        }
      },
    },

    groupListP: {
      handler(newV) {
        this.groupList = newV
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    initData(flag = true) {
      getMaterialWareList(this.page, this.pageSize, this.takeParams()).then(
        (res) => {
          this.canPushTagList = false
          if (flag) {
            this.getTagGroup()
          }
          this.dataList = res.list
          this.total = res.total
          this.debounceFlag = false
        }
      )
    },
    getTagGroup(flag = true) {
      getTagGroupList({
        page: this.tagPageSize,
        pageSize: 24,
        status: 1,
      }).then((tagGroup) => {
        if (tagGroup.list.length < 10) {
          this.showMoreTag = true
          this.resetTagFlag = true
        } else {
          this.resetTagFlag = false
          this.showMoreTag = false
        }
        const list = tagGroup.list
        if (this.firstInitData) {
          this.tagsList = list
          this.copyTagsList = JSON.parse(JSON.stringify(list)).map((item) => {
            return {
              ...item,
              allItemLen: item.tagItems.length, // ! 标签组下标签项的长度
              checkedTagList: [], // ! 标签组下被选中的标签项信息
              isChecked: false, // !标签组是否被选中
            }
          })
          this.firstInitData = false
        }
      })
    },
    changeDuration(val) {
      if (val) {
        const obj = {
          1: {
            startTime: '00:00:00',
            endTime: '00:00:10',
          },
          2: {
            startTime: '00:00:10',
            endTime: '00:00:30',
          },
          3: {
            startTime: '00:00:30',
            endTime: '00:01:00',
          },
          4: {
            startTime: '00:01:00',
            endTime: '00:02:00',
          },
          5: {
            startTime: '00:02:00',
            endTime: '23:59:59',
          },
        }
        this.startTime = obj[val].startTime
        this.endTime = obj[val].endTime
      } else {
        this.startTime = null
        this.endTime = null
      }
      this.initData()
    },
    uploadFiles(type) {
      if (type === 'video') {
        this.editCurInfoWithVideo = null
        this.showAddDialog = true
      }
      if (type === 'image') {
        this.editCurInfoWithPic = null
        this.showAddPicDialog = true
      }
    },
    currentChange(page) {
      this.page = page
      this.initData()
    },
    close() {
      this.isShowPreview = false
    },
    handleItemClick(val) {
      getMaterialDetail(val.id).then((res) => {
        this.curInfo = res
        this.isShowPreview = true
      })
    },
    handleMouseEnter(e, index) {
      if (!this.$refs.navItemList[index]) {
        return this.handleMouseLeave()
      }
      this.$refs.hoverPart.style.display = 'block'
      this.$refs.navItemList.forEach((item, i) => {
        if (i === index) {
          item.style.display = 'block'
        } else {
          item.style.display = 'none'
        }
      })
    },
    handleMouseLeave() {
      this.$refs.hoverPart.style.display = 'none'
      this.$refs.navItemList.forEach((item, i) => {
        item.style.display = 'none'
      })
      this.search = ''
    },
    handleContainerMouseLeave() {
      if (this.$refs.navItemList) {
        this.$refs.hoverPart.style.display = 'none'
        this.$refs.navItemList.forEach((item, i) => {
          item.style.display = 'none'
        })
      }
    },
    editItem(val) {
      getMaterialDetail(val.id).then((res) => {
        if (res.materialType === 1) {
          this.editCurInfoWithVideo = res
          this.showAddDialog = true
        }
        if ([2, 3].includes(res.materialType)) {
          this.editCurInfoWithPic = res
          this.showAddPicDialog = true
        }
      })
    },
    handleTagClick(tag) {
      // !点击了标签项
      const groupItem = this.copyTagsList.find(
        (item) => item.id === tag.tagGroupId
      )
      const groupIndex = this.copyTagsList.findIndex(
        (item) => item.id === tag.tagGroupId
      )
      if (groupItem.isChecked) {
        return this.$message.warning('已勾选该标签的标签组!')
      }
      // !
      if (groupItem.checkedTagList.find((item) => item.id === tag.id)) {
        return this.$message.warning('请勿重复勾选标签项!')
      }
      groupItem.checkedTagList = [...groupItem.checkedTagList, tag]
      if (groupItem.checkedTagList.length === groupItem.allItemLen) {
        groupItem.isChecked = true
      }
      this.$set(this.copyTagsList, groupIndex, { ...groupItem })

      this.debounceFlag = false
      this.canContionalLoading = true
      this.dataList = []
      this.page = 0
      this.getList(false)
    },
    handleTagGroupClick(val, e, index) {
      const target = e.target
      if (
        target.nodeName.toLowerCase() === 'input' ||
        target.classList.contains('subtag-list') ||
        target.classList.contains('subtag-ul') ||
        target.classList.contains('el-input__icon')
      ) {
        return
      }
      if (this.copyTagsList[index].isChecked) {
        return this.$message.warning('请勿重复选择标签组!')
      }
      const { id, tagItems } = val
      this.$set(this.copyTagsList, index, {
        ...this.copyTagsList[index],
        checkedTagList: [...this.copyTagsList[index].tagItems],
        isChecked: true,
      })
      this.debounceFlag = false
      this.canContionalLoading = true
      this.page = 0
      this.getList(false)
    },
    searchData() {
      this.page = 1
      this.canContionalLoading = true
      this.debounceFlag = false
      this.clearSelection()
      this.initData()
    },
    changeType(type = 1) {
      this.resetData()
      this.$set(this.params, 'materialType', type)

      this.clearSelection()
      this.initData()
    },
    delFilterTagAll() {
      this.dataList = []
      this.page = 1
      this.tagName = []
      this.tagItemIds = []
      this.groupTagIds = []
      this.booksNameIds = []
      this.imageTypeIds = []
      this.videoTypeIds = []
      this.booksTypeIds = []
      this.targetTagIds = []
      this.putChannelIds = []
      this.targetGroupIds = []
      this.debounceFlag = false
      this.canContionalLoading = true
      this.copyTagsList.forEach((item, index) => {
        this.$set(this.copyTagsList, index, {
          ...this.copyTagsList[index],
          isChecked: false,
          checkedTagList: [],
        })
      })
      this.initData()
    },
    delFilterTag(index, value) {
      const { isGroup, groupIndex } = value
      if (isGroup) {
        // todo=>
        this.$set(this.copyTagsList, groupIndex, {
          ...this.copyTagsList[groupIndex],
          checkedTagList: [],
          isChecked: false,
        })
      } else {
        const { checkedTagList } = this.copyTagsList[groupIndex]
        const targetIndex = checkedTagList.findIndex(
          (item) => item.id === value.id
        )
        checkedTagList.splice(targetIndex, 1)
        this.$set(this.copyTagsList, groupIndex, {
          ...this.copyTagsList[groupIndex],
          checkedTagList,
          isChecked: false,
        })
      }
      this.dataList = []
      this.canContionalLoading = true
      this.debounceFlag = false
      this.page = 1
      this.initData(false)
    },
    checkMore() {
      this.canPushTagList = true
      if (this.resetTagFlag) {
        this.resetTagFlag = false
        this.tagPageSize = 1
        this.tagsList = this.tagsList.slice(0, 10)
        this.showMoreTag = false
      } else {
        this.tagPageSize++
        this.getTagGroup()
      }
    },
    async freshData(value) {
      if (value) {
        const { materialType } = value
        this.params.materialType = materialType
      }
      this.debounceFlag = false
      this.canContionalLoading = true
      this.pageSize = this.page * this.pageSize
      this.page = 0
      await this.getList(false)
    },
    delLibrary(id) {
      this.$confirm('删除的素材将不可恢复，是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        delMaterialWare(id).then(() => {
          this.$message.success('删除成功!')
          this.canContionalLoading = true
          this.debounceFlag = false
          this.page = 1
          this.initData()
        })
      })
    },
    getList(flag = true) {
      return new Promise((resolve) => {
        if (this.debounceFlag) {
          return resolve()
        }
        if (!this.canContionalLoading) return resolve()
        this.debounceFlag = true
        this.page++
        getMaterialWareList(this.page, this.pageSize, this.takeParams())
          .then((res) => {
            if (flag) {
              this.dataList = [...this.dataList, ...res.list]
            } else {
              this.dataList = res.list
            }
            this.total = res.total
            this.debounceFlag = false
            if (res.list.length < this.pageSize) {
              this.canContionalLoading = false
            }
            return resolve()
          })
          .finally(() => {
            return resolve()
          })
      })
    },
    addClick({ id, isSelection, imgUrl, resources, doc }) {
      // console.log(id) //图片id
      // console.log(isSelection) //点击的图片是需要选中 还是删除
      // console.log(imgUrl) // 图片的 url 地址
      // console.log(resources) //图片的文件对象
      // console.log(doc) //当前图片的描述

      if (this.pictureSizeMode !== '3') {
        if (isSelection) {
          if (this.selection.length >= this.maxNum) {
            return this.$message.warning(`最多选择${this.maxNum}张图片!!`)
          }
          this.selection.push(id)
          this.imageList.push({
            resources,
            src: imgUrl,
            ...(!!doc && { doc }),
            id,
          })
        } else {
          const targetIndex = this.selection.findIndex((item) => item === id)
          if (targetIndex !== -1) {
            this.selection.splice(targetIndex, 1)
            this.imageList.splice(targetIndex, 1)
          }
        }
      } else {
        // 点击选择了图片 首先要 判断应该从哪个地方选择起走 其实就需要判断存不存在一个起始位置的索引 如果不存在的话  默认从第一个框开始新增
        this.groupList[this.activeIndex++] = {
          resources,
          src: imgUrl,
          ...(!!doc && { doc }),
          id,
        }
      }
    },
    handleTimeChange() {
      this.searchData()
    },
    addBatchTag() {
      getTagItemList({
        page: 1,
        pageSize: 999,
        tagItemName: '',
      }).then((res) => {
        this.tagsListWithAdd = res.list
        this.showAddTagDialog = true
        this.copyTagsListWithAdd = res.list
      })
    },
    handleDialogClose() {
      this.selection = []
      this.tagSelection = []
      this.searchAddTagName = ''
      this.showAddTagDialog = false
    },
    chooseTag(val) {
      if (this.tagSelection.includes(val.id)) {
        const targetIndex = this.tagSelection.findIndex(
          (item) => item === val.id
        )
        this.tagSelection.splice(targetIndex, 1)
      } else {
        this.tagSelection.push(val.id)
      }
    },
    clearSelection() {
      this.searchAddTagName = ''
      this.selection = []
      this.tagSelection = []
    },
    transformData(data) {
      this.$emit('transformData', data)
    },
    getTargetList(item) {
      return this.search
        ? item.tagItems.filter((tag) => tag.name.includes(this.search))
        : item.tagItems
    },
    takeParams() {
      const params = {
        materialTypes: this.mediaType
          ? this.mediaType === 'image'
            ? this.onlySingle
              ? [2]
              : [2, 3]
            : [1]
          : [this.params.materialType],
        content: this.params.content,
        title: this.params.title,
        videoTime: this.params.videoTime,
        adminUserId: this.params.adminUserId,
        startTime: this.startTime,
        endTime: this.endTime,
        createStartTime: this.params.createStartTime,
        createEndTime: this.params.createEndTime,
      }
      this.copyTagsList.forEach((item, index) => {
        const { checkedTagList } = item
        if (checkedTagList.length > 0) {
          params[this.mappingIds[6 - index].en] = item.checkedTagList.map(
            (item) => item.id
          )
        }
      })
      return params
    },
    getChooseTags() {
      let res = []
      res = this.copyTagsList.reduce((acc, b, c) => {
        if (b.checkedTagList.length === b.allItemLen) {
          acc.push({
            id: b.id,
            name: b.tagGroupName,
            isGroup: true,
            groupIndex: c,
          })
        } else {
          const { checkedTagList } = b
          const arr = []
          if (checkedTagList.length > 0) {
            checkedTagList.forEach((item, index) => {
              arr.push({
                id: item.id,
                isGroup: false,
                groupIndex: c,
                name: `${this.mappingIds[item.tagGroupId].cn}-${item.name}`,
              })
            })
            acc.push(...arr)
          }
        }
        return acc
      }, [])
      return res
    },
    resetData() {
      this.clearSelection()
      this.page = 1
      this.activeIndex = 0
      this.params = {}
      this.curInfo = {}
      this.tagName = []
      this.dataList = []
      this.endTime = null
      this.imageList = []
      this.tagItemIds = []
      this.tagPageSize = 1
      this.startTime = null
      this.groupTagIds = []
      this.targetTagIds = []
      this.isTagGroup = false
      this.targetGroupIds = []
      this.videoSelection = []
      this.debounceFlag = false
      this.canContionalLoading = true
      this.params.materialType = 2
    },
    handlePicItemClick(index) {
      this.activeIndex = index
    },
    deletePic(index) {
      let currentObj = JSON.parse(JSON.stringify(this.groupList))
      if (currentObj.hasOwnProperty(`${index}`)) {
        delete currentObj[index]
      }

      this.groupList = { ...currentObj }
    },
  },
  beforeDestroy() {
    document.querySelector('#mainCont').onmousewheel = null
  },
  components: {
    LibraryItem,
    AddPicture,
  },
}
</script>
<style lang="scss" scoped>
@mixin shadow-white {
  box-shadow: 0 0 0 3px #fff;
}
.material-warehouse-library {
  display: flex;
  .right_wrap {
    flex: 1;
    height: 100%;
  }
  .upload-files {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 14px;
    > span {
      margin-right: auto;
      font-size: 20px;
      font-weight: bold;
    }
    > div {
      margin-left: auto;
    }
  }
  .detail {
    padding: 0 20px 20px 20px;
    .nav-filter {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      border-bottom: 1px solid #e9f0f7;
      position: sticky;
      background: #fff;
      z-index: 999;
      top: -16px;
      padding-top: 20px;
      .filter-list {
        padding-bottom: 20px;
        width: 100%;
        .advantage-filter {
          color: #475669;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          > span {
            padding-right: 42px;
            margin-top: 10px;
            width: 107px;
          }
          > div {
            > div {
              margin-bottom: 10px;
            }
          }
        }
        .tags-filter {
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          .title {
            width: 108px;
            margin-top: 5px;
          }
          .tag-list {
            width: 90%;
            .underline-navbar {
              display: flex;
              flex-wrap: wrap;
              max-width: 1300px;
              li {
                position: relative;
                padding: 9px 22px;
                cursor: pointer;
                font-size: 14px;
                margin-right: 10px;
                margin-bottom: 12px;
                color: #b7b7b7;
                background: #f1f1f1;
                transition: all 300ms;
                border-radius: 3px;
                &:hover {
                  background: #00bf8a;
                  color: #fff;
                }
                &::before {
                  position: absolute;
                  left: 100%;
                  top: 0;
                  border-bottom: 2px solid transparent;
                  width: 0;
                  height: 100%;
                  content: '';
                  transition: all 300ms;
                }
                &:active {
                  color: #fff;
                }
                .subtag-list {
                  position: absolute;
                  display: none;
                  top: 140%;
                  left: 0;
                  width: 500px;
                  background: #f1f4f6;
                  padding: 15px;
                  border-radius: 6px;
                  z-index: 99;
                  .subtag-ul {
                    max-height: 525px;
                    overflow: auto;
                    li {
                      margin-bottom: 10px;
                      display: inline-block;
                      min-width: 70px;
                      text-align: center;
                      background: #fff;
                      border: 1px solid #b7b7b7;
                      color: #b7b7b7;
                      &:hover {
                        color: #00bf8a;
                        border-color: #00bf8a;
                      }
                    }
                  }
                  &::before {
                    content: '';
                    position: absolute;
                    background: transparent;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-color: transparent transparent #f1f4f6 transparent;
                    border-width: 0 20px 20px 20px;
                    top: -8px;
                    left: 10px;
                  }
                }
              }
            }
          }
        }
        .choose-tags {
          > div {
            line-height: 26px;
            font-size: 14px;
            margin-left: 25px;
            width: calc(100% - 150px);
          }
        }
      }
    }
    .main-container {
      width: 100%;
      margin-top: 10px;
      .nav-type {
        font-size: 18px;
        font-weight: bold;
        position: relative;
        > span {
          position: relative;
          cursor: pointer;
          // margin-right: 58px;
          transition: all 0.2s;
          .active-line {
            position: absolute;
            display: inline-block;
            bottom: -10px;
            left: 0;
            width: 100%;
            height: 4px;
            border-radius: 4px;
            background-color: #00bf8a;
          }
          &:hover {
            color: #00bf8a;
          }
        }
        .tips {
          white-space: nowrap;
          position: absolute;
          top: 50%;
          left: 90%;
          transform: translateY(-50%);
          color: #ccc;
          font-size: 14px;
          font-weight: normal;
        }
        .active-text {
          color: #00bf8a;
        }
      }
      .library-container {
        margin-top: 30px;

        .library-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > div:nth-of-type(1) {
            font-weight: bold;
          }
        }
        .library-container-list {
          display: flex;
          margin-top: 10px;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          height: 400px;
          overflow: auto;
          padding: 3px;
          > div {
            margin-right: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
.tags-list {
  margin-top: 20px;
  max-height: 200px;
  overflow: auto;
  .tag-item {
    margin: 0 10px 20px 0;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      color: #00bf8a;
      border-color: #00bf8a;
    }
  }
  .is-active {
    color: #fff !important;
    background-color: #00bf8a;
    border-color: #00bf8a;
  }
}
.file-download-status {
  position: fixed;
  z-index: 9999;
  overflow: auto;
  top: 100px;
  left: 75%;
  background: #fff;
  width: 300px;
  ul {
    list-style: none;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 460px;
    min-height: 120px;
    li {
      position: relative;
      padding: 15px 4px;
      padding-left: 65px;
      padding-right: 18px;
      // height: 60px;
      border-bottom: 1px solid rgb(223, 223, 223);
      .item-icon {
        position: absolute;
        left: 0;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #fff;
      }
      .item-info {
        color: #303942;
        font-size: 12px;
        > span {
          display: block;
          height: 20px;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: default;
        }
        .item-url {
          color: #1e1eaf;
        }
      }
    }
  }
}

.three_pic {
  padding: 10px 20px 20px 20px;
  flex-shrink: 0;
  width: 290px;
  height: 700px;

  .three_pic_title {
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .three_pic_item_wrap {
    overflow-y: scroll;
    display: flex;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
  }
  .three_pic_item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 73px;
    height: 73px;
    border-radius: 5px;
    border: 1px dashed #ccc;
    margin-right: 7px;
    margin-bottom: 7px;
    font-size: 18px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    .lajitong {
      position: absolute;
      display: none;
      left: 0;
      bottom: 0;
      right: 0;
      height: 30px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.4);
      text-align: center;

      i {
        cursor: pointer;
        display: inline-block;
        margin-top: 6px;
      }
    }
  }

  .three_pic_item:hover .lajitong {
    display: block;
  }

  .three_pic_item:nth-child(3n) {
    margin-right: 0;
  }
}

.activeItem {
  border: 1px solid rgb(52, 188, 82) !important;
}
</style>
